import React, { useEffect,useState} from 'react';
import {Helmet} from "react-helmet";
import './ReturnSurvey.css';

export const ReturnSurvey = () => {


    const [error, setError] = useState(false);


    useEffect(() => {
        const scriptTag = document.createElement("script");
        scriptTag.text = `(function(t,e,s,o){
            var n,c,l;
            t.SMCX=t.SMCX||[],e.getElementById(o)||(n=e.getElementsByTagName(s),c=n[n.length-1],l=e.createElement(s),l.type="text/javascript",l.async=!0,l.id=o,l.src="https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd_2F1P_2B59EX_2FxfFwX4gkVO_2F8qKKTeleaDu2crTNxNNEO4r.js",c.parentNode.insertBefore(l,c))})(window,document,"script","smcx-sdk");`
        document.body.appendChild(scriptTag);

        setTimeout(() => {
            const data = document.getElementsByClassName('smcx-modal');
            if(data[0] === undefined){
               setError(true)
            }
        }, 1000)
    }, [])

    return <div className='survey'> { error && <div className='survey-error'>This survey has already been submitted or is open in another tab or browser.</div>
        }</div>
}