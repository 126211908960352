import logo from './logo.svg';
import './App.css';
import {ReturnSurvey} from './Pages/ReturnSurvey/ReturnSurvey';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
              <Route path={'/70CD293AD630405EA4F1B3CC55311DF2'} element={<ReturnSurvey/>}>
              </Route>
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
